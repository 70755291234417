@tailwind base;
@tailwind components;
@tailwind utilities;


.banner {
  background: url("./assets/banner.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
}

.banner02 {
  background: url("./assets/banner02.svg");
  background-color: #315ade;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
}

.banner03 {
  background: url("./assets/banner03.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
}

.banner04 {
  background: url("./assets/banner04.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
}

.fadeLeftMini {
  animation: fadeLeftMini 1s;
  animation-fill-mode: both;
}

.fadeRightMini {
  animation: fadeRightMini 1s;
  animation-fill-mode: both;
}

@keyframes fadeLeftMini {
  0% {
    opacity: 0;
    transform: translate3d(-40%, 0, 0);
  }
  100% {
    opacity: 1;
    transform: translateZ(0);
  }
}

@keyframes fadeRightMini {
  0% {
    opacity: 0;
    transform: translate3d(40%, 0, 0);
  }
  100% {
    opacity: 1;
    transform: translateZ(0);
  }
}


